import { useStaticQuery, graphql } from 'gatsby';

import App from 'containers/App';

function prefixWithDirectusFileEndpoint(id: string): string {
    return `${process.env.DIRECTUS_ENDPOINT}/assets/${id}`;
}

const Home = () => {
    const { directus } = useStaticQuery(query);

    return (
        <>
            <div>GATSBY API - DIRECTUS QUERY</div>
            <App content={directus} />
            <br />
            <div>
                <div>PRECACHED IMAGE</div>
                <img src="/assets/app-logo.png" />
            </div>
            <br />
            <div>
                <div>RUNTIME CACHE IMAGE</div>
                <img src="https://directus.attexp.com/assets/e483eacc-ff75-4545-92fa-60a68019b593" />
            </div>
            <br />
            <div>
                <div>PRECACHED VIDEO</div>
                <video
                    width={400}
                    height={200}
                    controls
                    src={prefixWithDirectusFileEndpoint(
                        directus.quote[directus.quote.length - 1].audio_file?.id
                    )}
                />
            </div>
            <br />
            <div>
                <div>RUNTIME CACHE VIDEO</div>
                <video
                    width={400}
                    height={200}
                    controls
                    src="https://audioexperience-directus.attexp.com/assets/13efdc87-e68c-4440-a87e-4e6fc214b10b"
                />
            </div>
            <br />
            <a href="/sw/">SW configuration</a>
        </>
    );
};

export default Home;

// Static Directus query
// This example works with the https://audioexperience-directus.attexp.com endpoint
export const query = graphql`
    query {
        directus {
            quote {
                answer_1
                audio_file {
                    id
                }
            }
        }
    }
`;
